<template>
  <div class="targrt-content">
    <page-content class="page-content" :contentTableConfig="contentTableConfig" :queryInfo="{}"></page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {


    return {
      contentTableConfig
    }

  }
})
</script>

<style scoped lang="less">
.targrt-content {}
</style>




