<template>
  <div class="main-target">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <top-chart></top-chart>
      <target-content></target-content>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import TopChart from './cpns/top-chart/top-chart.vue'
import targetContent from './cpns/target-content/target-content.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    TopChart,
    targetContent
  },
  setup() {
    const route = useRoute()

    watch(() => route.path, (n) => {
      // console.log(n);
    }, {
      deep: true
    })

    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.main-target {
  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




