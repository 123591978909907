<template>
  <div class="page-content">
    <hy-table :align="align" :isBorder="isBorder" @cellDblclick="cellDblclick" :listData="dataList"
      :listCount="dataCount" v-bind="contentTableConfig" v-model:page="pageInfo" @selectionChange="selectionChange"
      @tableCellClick="tableCellClick" :showFooter="false">
      <template #pagination>
        <div class="pagination">
          <slot name="pagination"></slot>
        </div>
      </template>
      <!-- 在page-content中动态插入剩余的插槽 -->
      <template v-for="item in contentTableConfig.propList" :key="item.prop" #[item.slotName]="scope">
        <template v-if="item.slotName">
          <slot :name="item.slotName" :row="scope.row"></slot>
        </template>
      </template>
    </hy-table>
  </div>
</template>

<script lang="js">
import { defineComponent, computed, ref, watch } from 'vue'
import HyTable from '@/base-ui/table'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
export default defineComponent({
  components: {
    HyTable
  },
  props: {
    align: {
      type: String,
      default: 'left'
    },
    isBorder: {
      type: Boolean,
      default: false
    },
    contentTableConfig: {
      type: Object,
      require: true
    },
    pageName: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    isButtonStyle: {
      type: Boolean,
      default: false
    },
    queryInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    dataList: {
      type: Array,
      default() {
        return []
      }
    },
    dataCount: {
      type: Number,
      default: 0
    }

  },
  emits: ['newBtnClick', 'editBtnClick', 'selectionChange', 'tableCellClick', 'cellDblclick'],
  setup(props, { emit }) {
    const storeCategory = ref(props.category)
    const store = useStore()

    // 1.双向绑定pageInfo
    const pageInfo = ref({ currentPage: 1, pageSize: 10 })


    // 5.删除/编辑/新建操作
    const handleDeleteClick = (item) => {

    }

    const handleEditClick = (item) => {
      // emit('editBtnClick', item)
    }

    const cellDblclick = (item) => {
      emit('cellDblclick', item)
    }


    const selectionChange = (value) => {
      emit('selectionChange', value)
    }

    const tableCellClick = (value) => {

      emit('tableCellClick', value)
    }






    return {
      storeCategory,
      pageInfo,
      handleDeleteClick,
      handleEditClick,
      selectionChange,
      tableCellClick,
      cellDblclick
    }
  }
})
</script>

<style scoped lang="less">
.page-content {
  .pagination {
    margin-top: 10px;
    text-align: right;

  }
}
</style>
