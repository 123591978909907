export const breadcrumbList = [
  {
    name: '目标',
    path: ''
  },
  {
    name: '总览目标',
    path: ''
  }
]
