<template>
  <div class="top-chart">
    <div class="left" id="leftChart" :style="{ width: '280px', height: '300px' }"></div>
    <div class="center" id="centerChart" :style="{ width: '280px', height: '300px' }"></div>
    <div class="right" id="rightChart" :style="{ width: '500px', height: '300px' }"></div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onMounted } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    onMounted(() => {

      let leftChart = echarts.init(document.getElementById("leftChart"));
      leftChart.setOption(
        {
          xAxis: {
            type: 'value',

          },
          yAxis: {
            type: 'category',
            data: ['实际完成', '销售目标']
          },
          series: [
            {
              data: [120, 200],
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }
          ]
        }
      )



      let centerChart = echarts.init(document.getElementById("centerChart"));
      centerChart.setOption(
        {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 204, name: '已完成' },
                { value: 105, name: '未完成' },

              ]
            }
          ]
        }
      )



      let rightChart = echarts.init(document.getElementById("rightChart"));
      rightChart.setOption(
        {
          xAxis: {
            type: 'category',
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [120, 200, 150, 80, 70, 110, 130, 123, 56, 199, 299, 199],
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }
          ]
        }
      )





    })

    return {

    }

  }
})
</script>

<style scoped lang="less">
.top-chart {
  display: flex;
  min-width: 960px;

  .left,
  center {
    flex: 1;
  }

  .right {
    flex: 2;
  }
}
</style>




