<template>
  <div class="hy-table">
    <div class="header">
      <slot name="header">
        <div class="title">{{ title }}</div>
        <div class="handler">
          <slot name="headerHandler"></slot>
        </div>
      </slot>
    </div>

    <el-table :height="600" header-row-class-name="pubiasd" :data="listData"
      :border="isBorder" style="width: 100%" @selection-change="handleSelectionChange" v-bind="childrenProps"
      class="table" highlight-current-row @current-change="handleTableCurrentChange" @cell-click="cellClick"
      @cell-dblclick="cellDblclick" @header-click="headerClick">
      <el-table-column v-if="showSelectColumn" type="selection" :align="align" width="60"></el-table-column>
      <el-table-column v-if="showIndexColumn" type="index" label="序号" :align="align" width="80"></el-table-column>
      <template v-for="propItem in propList" :key="propItem.prop">
        <el-table-column v-bind="propItem" :align="align" :show-overflow-tooltip="isShowTooltip"
          :fixed="propItem.fixed">
          <template #default="scope">
            <slot :name="propItem.slotName" :row="scope.row">{{ scope.row[propItem.prop] }}</slot>
          </template>
          <template #header="scope" v-if="propItem.header">
            <div class="table-header">
              <div>{{ propItem.prop }}</div>
              <div>{{ propItem.header }}</div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div></div>
    <div class="footer" v-if="showFooter">
      <slot name="footer">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page.currentPage" :page-size="page.pageSize" :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper" :total="listCount"></el-pagination>
      </slot>
    </div>
    <div>
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    height: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    listData: {
      type: Array,
      required: true
    },
    listCount: {
      type: Number,
      default: 0
    },
    propList: {
      type: Array,
      required: true
    },
    showIndexColumn: {
      type: Boolean,
      default: false
    },
    showSelectColumn: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({ currentPage: 1, pageSize: 10 })
    },
    childrenProps: {
      type: Object,
      default: () => ({})
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    isShowTooltip: {
      type: Boolean,
      default: false
    },
    isBorder: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'left'
    }
  },
  emits: ['selectionChange', 'update:page', 'tableCellClick', 'tableCellClick', 'cellDblclick'],
  setup(props, { emit }) {
    const handleSelectionChange = (value) => {
      emit('selectionChange', value)
    }

    const handleCurrentChange = (currentPage) => {
      emit('update:page', { ...props.page, currentPage })
    }

    const handleSizeChange = (pageSize) => {
      emit('update:page', { ...props.page, pageSize })
    }

    const handleTableCurrentChange = (value) => {
      // // console.log(value);
    }

    let t
    const cellClick = (row, column, cell, event) => {

      clearTimeout(t)
      t = setTimeout(() => {
        emit('tableCellClick', { ...row, ...column })
      }, 250);



    }

    const cellDblclick = (row, column, cell, event) => {
      clearTimeout(t)
      emit('cellDblclick', { ...row, ...column })

    }

    const headerClick = (column, event) => {
      // // console.log(column);
      // // console.log(event);
    }

    return {
      headerClick,
      handleSelectionChange,
      handleCurrentChange,
      handleSizeChange,
      handleTableCurrentChange,
      cellClick,
      cellDblclick
    }
  }
})
</script>

<style scoped lang="less">
&:deep(.is-leaf) {
  background-color: rgb(239, 239, 239) !important;
}

&:deep(.el-table) {
  font-size: 12px;

  .el-checkbox {
    height: auto !important;
  }


  .el-table__header-wrapper {
    .el-table__cell {

      background-color: rgba(239, 239, 239, 1);
      padding: 9px 0 !important;
      // border-right:1px solid rgb(223, 223, 223);

      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }

  }
}


.header {
  display: flex;
  // height: 45px;
  // padding: 0 5px;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .handler {
    align-items: center;
  }

  .table {}
}

.footer {
  margin-top: 15px;

  .el-pagination {
    text-align: right;
  }
}
</style>
