export const contentTableConfig = {
  propList: [
    { prop: 'buyName', label: '销售姓名', minWidth: '200', slotName: 'serve' },
    { prop: 'success', label: '完成率', minWidth: '100' },
    {
      prop: 'January',
      label: '1月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'February',
      label: '2月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'March',
      label: '3月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'April',
      label: '4月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'May',
      label: '5月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'June',
      label: '6月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'July',
      label: '7月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'August',
      label: '8月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'September',
      label: '9月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'October',
      label: '10月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'November',
      label: '11月',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'December',
      label: '12月',
      minWidth: '120',
      slotName: 'serve_time'
    },

    { label: '操作', minWidth: '240', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}


